// CORE
import { Action, getModule, Module, VuexModule } from 'vuex-module-decorators'

// STORE
import store from '@/store'

//INTERFACE
import {
  PenaltyPaymentPostRequest,
  PenaltyPaymentRequest,
  PenaltyPaymentStatusTokenGetRequest,
} from '../types'

/**
 * Одиночные функции общие для обоих ролей
 * - Оплата штрафов (Payment)
 */
@Module({
  dynamic: true,
  name: 'common',
  store,
  namespaced: true,
})
class Common extends VuexModule {
  // 0==================================================== PAYMENT ====================================================+o>
  // Получить ссылку на оплату
  @Action({ rawError: true })
  async paymentPenalties (payload: { params: PenaltyPaymentRequest, user?: { role: string, id: string } }) {
    const { data } = await PenaltyPaymentPostRequest(payload.params)

    if (payload.user) {
      // NOTE: Пишем в локал сторейдж инфу о пользователе
      localStorage.setItem('userRoleID', JSON.stringify(payload.user))
    }

    return data
  }

  // Проверить статус платежа
  @Action({ rawError: true })
  async checkPaymentStatus (payload: string) {
    const { data } = await PenaltyPaymentStatusTokenGetRequest(payload)

    return data
  }

}

const CommonModule = getModule(Common)

export default CommonModule
