




























// CORE
import { Component, Vue, Prop } from 'vue-property-decorator'

// COMPONENTS
import CaptionCard from '@/components/cards/CaptionCard.vue'
import DrawerHeader from '@/components/drawers/DrawerHeader.vue'

// STORE
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    CaptionCard,
    DrawerHeader,
  },
})
export default class PaymentStatus extends Vue {
  @Prop({ required: true })
  private status!: string

  private isAuthed = false

  private role!: string
  private entity!: string

  private created () {
    AuthModule.getUserResource()
      .then(() => {
        this.isAuthed = true
        if (localStorage.getItem('userRoleID')) {
          const userRoleID: { role: string, entity: string } = JSON.parse(localStorage.getItem('userRoleID') as string)

          this.role = userRoleID.role
          this.entity = userRoleID.entity
        }
      })
      .catch(() => {
        this.isAuthed = false
      })
  }

  private getPath (name: string) {
    return {
      name: name,
      params: {
        role: this.role,
        entity: this.entity,
      },
    }
  }
}
