
































// CORE
import { Component, Mixins } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// STORE
import CommonModule from '@/store/modules/common'

// MIXINS
import NotifyMixin from '@/mixins/NotifyMixin'

// COMPONENT
import IntermarkLabel from '@/components/IntermarkLabel.vue'
import AuthFormWrapper from '@/components/AuthFormWrapper.vue'
import PaymentStatus from '@/components/PaymentStatus.vue'

// INTERFACES
import { PaymentStatusEnum } from '@/store/types'

@Component({
  components: {
    IntermarkLabel,
    AuthFormWrapper,
    PaymentStatus,
  },
})
export default class Callback extends Mixins(NotifyMixin) {
  private metaInfo (): MetaInfo {
    return {
      title: 'Статус оплаты',
    }
  }

  private status = ''

  private created () {
    if (!this.$route.params.token?.length) {
      this.$router.push('/')
      this.notifyError('Отсутствует токен оплаты')
    } else {
      CommonModule.checkPaymentStatus(this.$route.params.token)
        .then(status => {
          switch (status) {
          case PaymentStatusEnum.DECLINED:
            this.status = 'Ваш платеж отменён'
            break
          case PaymentStatusEnum.CREATED:
          case PaymentStatusEnum.WAITING:
          case PaymentStatusEnum.PAID:
          case PaymentStatusEnum.REFUND:
            this.status = 'Ваш платеж принят'
            break
          default:
            this.notifyError('Что-то пошло не так, вы можете посмотреть оплачен ли статус на странице штрафов в личном кабинете Intermark.')
            this.$router.push('/penalty')
            break
          }
        })
    }
  }
}
